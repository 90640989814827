import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
  fontSize: "30px",
};


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Digital Restaurant</title>
      <h1>Digital Restaurant</h1>
      <h3>Custom Development Provider</h3>
    </main>
  );
};

export default IndexPage;
